import ApiService from '@/services/ApiService'
import {BtoF} from '@/services/MomentService'
import Gate from '@/services/Gate/Gate'

export default {
    state: {
        projects: [],
        project: {},
        entity: {},
        permissions: {
            view: false,
            create: false,
            edit: false,
            delete: false,
            scopeCreate: false,
            taskCreate: false,
        },
    },
    getters: {
        projects: (state) => state.projects,
        projectPermissions: (state) => state.permissions,
    },
    mutations: {
        setProjects: (state, data) => (state.projects = data),
        setProject: (state, data) => (state.project = data),
        setProjectEntity: (state, data) => (state.entity = data),
        setProjectPermissions: async (state) => {
            state.permissions = {
                view: await Gate.can('view', 'project', state.entity.id),
                create: await Gate.can('create', 'project'),
                edit: await Gate.can('edit', 'project', state.entity.id),
                delete: await Gate.can('delete', 'project', state.entity.id),
                editOrCreate: await Gate.can('editOrCreate', 'project', state.entity.id),
                scopeCreate: await Gate.can('scopeCreate', 'project', state.entity.id),
                taskCreate: await Gate.can('taskCreate', 'project', state.entity.id),
            }
        },
    },
    actions: {
        fetchProjectPermissions({commit}, id) {
            commit('setProjectEntity', {})
            return new Promise((resolve, reject) => {
                ApiService.api.app
                    .getModelPermissions('project', id)
                    .then((res) => {
                        commit('setProjectEntity', res.data.data)
                        commit('setProjectPermissions')
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        commit('setProjectEntity', {})
                        commit('setProjectPermissions')
                        resolve({})
                        reject(err)
                    })
            })
        },
        fetchProjects({commit}, data) {
            commit('setProjects', [])
            return new Promise((resolve, reject) => {
                ApiService.api.projects
                    .fetchProjects(data)
                    .then((res) => {
                        const data = res.data.data.map((i, index) => ({
                            id: i.id,
                            company_name: i.company_name,
                            project_name: i.name,
                            internal_number: i.internal_number,
                            owner_name: i.owner?.owner_name,
                            owner_id: i.owner?.id,
                            stage: i.stage,
                            estimated_start_date: BtoF({value: i.estimated_start_date}),
                            estimated_end_date: BtoF({value: i.estimated_end_date}),
                            manager_full_name: i.manager_full_name,
                            manager_phone: i.manager_phone,
                            manager_email: i.manager_email,
                            manager_id: i.manager_id,
                            company_id: i.company_id,
                        }))
                        commit('setProjects', data)
                        resolve({...res.data, data: data})
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        createProject(vm, data) {
            return new Promise((resolve, reject) => {
                ApiService.api.projects
                    .createProject(data)
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        updateProject(vm, data) {
            return new Promise((resolve, reject) => {
                ApiService.api.projects
                    .updateProject(data)
                    .then((res) => {
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        fetchContracts(vm, data) {
            return new Promise((resolve, reject) => {
                const data = [...new Array(10)].map((i, index) => ({
                    id: index,
                    file_name: '01/11/2021',
                    task_name: '04:05 pm',
                    company: 'Contracts Connected',
                    due_date: 'Lorem ipsun',
                    status: 'Lorem ipsun',
                    priority: 'Lorem ipsun',
                    internal_owner: 'Lorem ipsun',
                    assigned_to: 'Lorem ipsun',
                }))
                resolve(data)
            })
        },
        teamAdd(vm, {users, id}) {
            return new Promise((resolve, reject) => {
                const formData = new FormData()
                formData.append('project_id', id)
                users.forEach((item) => {
                    formData.append('users[]', item)
                })
                ApiService.api.projects
                    .teamAdd(formData)
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        teamRemove(vm, {users, id}) {
            return new Promise((resolve, reject) => {
                const formData = new FormData()
                formData.append('project_id', id)
                users.forEach((item) => {
                    formData.append('users[]', item)
                })
                ApiService.api.projects
                    .teamRemove(formData)
                    .then((res) => {
                        resolve(res.data)
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getScopeApprovalRounds(vm, data) {
            return new Promise((resolve, reject) => {
                ApiService.api.projects
                    .getScopeApprovalRounds(data)
                    .then((res) => {
                        resolve({
                            ...res.data,
                            data: res.data.data.sort((a, b) => {
                                if (a.name > b.name) {
                                    return 1
                                }
                                if (a.name < b.name) {
                                    return -1
                                }
                                return 0
                            }),
                        })
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        getStages(vm, data = {}) {
            return new Promise((resolve, reject) => {
                ApiService.api.projects
                    .getStageList(data)
                    .then((response) => resolve(response.data.data))
                    .catch((err) => reject(err))
            })
        },
    },
}
