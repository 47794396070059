const Templates = () => import('@/views/Templates');
const TemplatesList = () => import('@/views/Templates/List');
const TemplateDetails = () => import('@/views/Templates/Template/Details.vue');
const TemplateCreate = () => import('@/views/Templates/Template/Create');
const TemplateEditor = () => import('@/views/Templates/Template/Editor');
const TemplateEdit = () => import('@/views/Templates/Template/Edit');

import {canCreate, canListView, canView} from './middleware/template';

export default [
    {
        name: 'Templates',
        component: Templates,
        redirect: {name: 'TemplatesList'},
        path: 'templates',
        children: [
            {
                path: '',
                name: 'TemplatesList',
                beforeEnter: async () => await canListView(),
                component: TemplatesList,
            },
            {
                path: 'create',
                name: 'TemplateCreate',
                beforeEnter: async () => await canCreate(),
                component: TemplateCreate,
            },
            {
                name: 'Template',
                path: ':id',
                component: TemplateDetails,
                beforeEnter: async (to) => await canView({to: to}),
                children: [
                    {
                        name: 'TemplateEdit',
                        path: 'edit',
                        component: TemplateEdit,
                    },
                    {
                        name: 'TemplateEditor',
                        path: 'editor',
                        component: TemplateEditor,
                    },
                ],
            },
        ],
    },
];
