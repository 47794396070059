import i18n from '@/i18n'
import {nextTick} from 'vue'

const getMessage = (message, value, customMessage) => {
    const isSimpleMessage = typeof message === 'string'
    let messageKey = isSimpleMessage ? message : message[0]
    let messageVars = isSimpleMessage ? {} : (message[1] || {})
    messageVars.value = value

    if (customMessage) {
        const isSimpleCustomMessage = typeof customMessage === 'string'
        messageKey = isSimpleCustomMessage ? customMessage : customMessage[0]
        messageVars = {
            ...messageVars,
            ...(isSimpleCustomMessage ? {} : (customMessage[1] || {}))
        }
    }

    return i18n.global.t(messageKey, messageVars)
}

const validate = (rules, value) => {
    for (let rule of rules) {
        let message = null;

        if (Array.isArray(rule)) {
            message = rule[1];
            rule = rule[0];
        }

        const result = rule(value)

        if (result === -1) {
            return ''
        }

        if (result !== true && result) {
            return getMessage(result, value, message)
        }
    }

    return ''
}

const validateAll = (rules, values) => {
    const rulesArr = Object.entries(rules)
    const validationErrors = {}
    for (let [field, fieldRules] of rulesArr) {
        const validationResult = validate(fieldRules, values[field])
        if (validationResult) {
            validationErrors[field] = validationResult
        }
    }

    return {
        hasErrors: !!Object.entries(validationErrors).length,
        validationErrors,
    }
}

const has = (field, errors) => {
    return !!errors[field]
}

const set = (field, errorMessage, errors) => {
    if (Array.isArray(errors[field])) {
        errors[field].push(errorMessage)
    } else {
        errors[field] = [errorMessage]
    }
}

export default rules => ({
    rules,
    errors: {},
    validate(field, value) {
        const fieldRules = this.rules[field]
        let errors = fieldRules ? validate(fieldRules, value) : ''
        this.errors[field] = errors
        return errors
    },
    validateAll(values) {
        let result = validateAll(this.rules, values)
        this.errors = result.validationErrors
        return result
    },
    has(field) {
        return has(field, this.errors)
    },
    set(field, errorMessage) {
        set(field, errorMessage, this.errors)
    },
    get(field) {
        if (this.errors[field]) {
            if (Array.isArray(this.errors[field])) {
                return this.errors[field][0]
            } else {
                return this.errors[field]
            }
        } else {
            return ''
        }
    },
    forget(field) {
        this.errors[field] = null
    },
    forgetAll() {
        this.errors = {};
    },
    scrollToErrors(querySelector = '.form-text.text-error') {
        nextTick(() => {
            let els = document.querySelectorAll(querySelector)
            if (els.length) {
                const top = els[0].getBoundingClientRect().top
                const y = top + window.pageYOffset + -250
                window.scrollTo({top: y, behavior: 'smooth'})
            }
        })
    },
})
