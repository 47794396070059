import axios from 'axios';
import {handleError} from './utils/ErrorHandler';
import config from "@/domain/config";

export default class BaseConfigs {
    api;
    baseUrl;
    endpointRoute;

    constructor() {
        this.baseUrl = config.api.baseUrl;

        this.createAxiosInstance();
        this.setDefaultHeaders();
        this.setInterceptors();
    }

    createAxiosInstance() {
        this.api = axios.create({
            baseURL: this.baseUrl,
            withCredentials: true,
            xsrfCookieName: 'XSRF-TOKEN',
        });
    }

    setDefaultHeaders() {
        this.api.defaults.headers = {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
        };
    }

    asFormData() {
        this.api.defaults.headers = {
            ...this.api.defaults.headers,
            'Content-Type': 'multipart/form-data'
        };

        return this.api;
    }

    asJson() {
        this.api.defaults.headers = {
            ...this.api.defaults.headers,
            'Content-Type': 'application/json'
        };

        return this.api;
    }

    async asDownload(route) {
        return this.asJson().get(route, {
            responseType: 'blob',
            headers: {
                'Cache-Control': 'no-cache'
            }
        });
    }

    replaceRouteBindings(bindings) {
        let route = this.endpointRoute;

        Object.keys(bindings)
            .forEach(key => {
                route = route.replace(`:${key}`, bindings[key]);
            });

        return route;
    }

    setInterceptors() {
        this.api.interceptors.response.use(undefined, (error) => handleError(error));
    }
}
