import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    getTask: (data) => asJson(axios).get(getVerUri('task'), data),
    fetchTasks: (data) => asJson(axios).get(getVerUri('task/list'), data),
    getTasksKanban: (data) => asJson(axios).get(getVerUri('task/dashboard'), data),
    editTasks: (data) => asJson(axios).post(getVerUri('task/edit'), data),
    skip: (id) => asJson(axios).post(getVerUri('task/skip/' + id)),
    stopRecurrence: (id) => asJson(axios).put(getVerUri('task/recurrence/' + id + '/stop')),
    updateRecurrence: (id, data) => asJson(axios).put(getVerUri('task/recurrence/' + id + '/update'), data),
    editTasksMass: (data) => asJson(axios).post(getVerUri('task/bulk-edit'), data),
    downloadTaskAttachment: (data) => asJson(axios).post(getVerUri('task/attachment/download/'), data, {responseType: 'blob'}),
    getShortcodes: (data) => asJson(axios).get(getVerUri('scope/shortcodes'), data),
    revokeTask: taskId => asJson(axios).post(getVerUri('task/revoke/' + taskId))
})
