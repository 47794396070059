<template>
    <div class="sign-up-wrap">
        <div class="sign-up">
            <h1>Confirm login credentials</h1>
            <p class="text-center">
                Congratulations on joining Contracts Connected!<br/>
                Please enter your email and password to access your account.
            </p>
            <CInputGroup class="mt-4">
                <CFormInput v-model="formData.email" maxlength="100" placeholder="Enter your Email"
                            autocomplete="username email" :invalid="!!validationErrors.email"
                            @blur="handleChangeField('email', $event)"/>
            </CInputGroup>
            <CFormText v-if="validationErrors.email" style="color: red">
                {{ validationErrors.email }}
            </CFormText>
            <CInputGroup class="mt-4">
                <CFormInput v-model="formData.password" :type="visPass ? 'password' : 'text'"
                            placeholder="Enter your Password" autocomplete="curent-password"
                            :invalid="!!validationErrors.password" :class="{ isInvalid: !!validationErrors.password }"
                            class="no-append" @blur="handleChangeField('password', $event)"
                            @keyup.enter="handleSubmit"/>
                <img v-if="visPass" class="password-visibility-toggle" src="@/assets/eye.svg"
                     @click.self.stop="visPass = false"/>
                <img v-else src="@/assets/eye-slash.svg" class="password-visibility-toggle"
                     @click.self.stop="visPass = true"/>
            </CInputGroup>
            <CFormText v-if="validationErrors.password" style="color: red">
                {{ validationErrors.password }}
            </CFormText>
            <CInputGroup class="mt-4">
                <CFormInput v-model="formData.password_confirmation" :type="visConfirm ? 'password' : 'text'"
                            placeholder="Enter Confirm Password" autocomplete="curent-password"
                            :invalid="!!validationErrors.password_confirmation"
                            :class="{ isInvalid: !!validationErrors.password_confirmation }"
                            class="no-append" @blur="handleChangeField('password_confirmation', $event)"
                            @keyup.enter="handleSubmit"/>
                <img v-if="visConfirm" class="password-visibility-toggle" src="@/assets/eye.svg"
                     @click.self.stop="visConfirm = false"/>
                <img v-else src="@/assets/eye-slash.svg" class="password-visibility-toggle"
                     @click.self.stop="visConfirm = true"/>
            </CInputGroup>
            <CFormText v-if="validationErrors.password_confirmation" style="color: red">
                {{ validationErrors.password_confirmation }}
            </CFormText>
            <CRow class="justify-content-center mt-4">
                <LoadingButton color="primary" class="px-4" :loading="submitLoading"
                               :disabled="validator.validateAll(formData).hasErrors"
                               @click="handleSubmit">
                    Save password and login
                </LoadingButton>
            </CRow>
            <CRow class="mt-4">
                <CCol class="bottomInfo text-center">
                    <p>
                        By clicking the button, you agree to our
                        <a href="https://www.contractsconnected.com/terms-and-conditions" target="_blank">
                            Terms of Service
                        </a>
                        and have read and acknowledge our
                        <a href="https://www.contractsconnected.com/privacy-policy" target="_blank"> Privacy Policy</a>
                    </p>
                </CCol>
            </CRow>
        </div>
    </div>
</template>

<script>
import validator from '@/utils/validator';
import rules from '@/utils/validator/rules';
import {parseError} from '@/utils/api';
import LoadingButton from "@/components/LoadingButton.vue";

export default {
    components: {LoadingButton},
    inject: ['toast'],
    data() {
        return {
            submitLoading: false,
            formData: {
                token: '',
                email: '',
                password: '',
                password_confirmation: '',
            },
            validator: {},
            validationErrors: {},
            visPass: true,
            visConfirm: true,
        };
    },
    created() {
        this.setRules();
        this.formData.email = this.$route.query.email || '';
        this.formData.token = this.$route.query.token || '';
    },
    mounted() {
        window.onbeforeunload = () => 'You have unsaved changes!';
    },
    methods: {
        handleChangeField(field, event) {
            this.validationErrors[field] = this.validator.validate(field, event.target.value);
        },
        setRules() {
            this.validator = validator({
                email: [rules.required, rules.email],
                password: [rules.required, rules.password],
                password_confirmation: [rules.required, rules.passwordConfirmation(() => this.formData.password)],
            });
        },
        handleSubmit() {
            const validationResult = this.validator.validateAll(this.formData);
            if (!validationResult.hasErrors) {
                this.submitLoading = true;
                this.$http.superadmin
                    .signUp(this.formData)
                    .then((res) => {
                        this.$store.commit('setUser', res.data.data);
                        this.$router.push({name: 'SettingCompanyDetails'});
                    })
                    .catch((error) => {
                        let err = parseError(error);
                        if (err.validationMessages) this.validationErrors = err.validationMessages;
                        if (err.message) this.toast('error', err.message);
                    })
                    .finally(() => (this.submitLoading = false));
            } else {
                this.validationErrors = validationResult.validationErrors;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.sign-up {
    background: #ffffff;
    box-shadow: 0 3px 6px rgba(48, 60, 84, 0.08);
    width: 578px;
    padding: 40px 55px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-text {
        width: 100%;
    }

    &-wrap {
        height: 100vh;
        background: #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-transform: uppercase;
        text-align: center;
        color: #000000;
    }

    p {
        color: #303c54;
        font-size: 16px;
        line-height: 21.28px;
    }

    a {
        text-decoration: none;
        color: #005d9d;
    }

    .bottomInfo {
        a,
        p {
            font-size: 14px;
            line-height: 18.62px;
            font-weight: 400;
        }
    }
}
</style>

<style>
.is-invalid + .password-visibility-toggle {
    margin-right: 1.7rem;
}

.password-visibility-toggle {
    width: 20px;
    object-fit: contain;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    cursor: pointer;
}
</style>
