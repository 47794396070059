const Login = () => import('@/views/Auth/Login')
const RecoveryPassword = () => import('@/views/Auth/RecoveryPassword')
const UserSignUp = () => import('@/views/Auth/UserSignUp')
const CompanySignUp = () => import('@/views/Auth/CompanySignUp')
const ContactUs = () => import('@/views/Auth/ContactUs.vue')
import {canLoginView} from './middleware/auth'

export default [
    {
        path: '/login',
        name: 'Login',
        beforeEnter: async () => await canLoginView(),
        component: Login,
    },
    {
        path: '/recovery-password',
        name: 'RecoveryPassword',
        component: RecoveryPassword,
    },
    {
        path: '/user/sign-up',
        name: 'SignUp',
        component: UserSignUp,
    },
    {
        path: '/company/sign-up',
        name: 'CompanySignUp',
        component: CompanySignUp,
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: ContactUs,
    },
]
