import {cilUsers} from './cilUsers'
import {cilDocument} from './cilDocument'
import {cilTasks} from './cilTasks'
import {cilCompanies} from './cilCompanies'
import {cilProjects} from './cilProjects'
import {cilInfoCircle} from './cilInfoCircle'
import {cilInfoSquare} from './cilInfoSquare'
import {cilArBottomBlue} from './cilArBottomBlue'
import {cilArRight} from './cilArRight'
import {cilArTopBlue} from './cilArTopBlue'
import {cilArBottomBlack} from './cilArBottomBlack'
import {cilArrowUp} from './cilArrowUp'
import {cilArrowDown} from './cilArrowDown'
import {cilUploadPlace} from './cilUploadPlace'
import {cilActionLeft} from './cilActionLeft'
import {cilFileCheck} from './cilFileCheck'
import {cilReplaceFile} from './cilReplaceFile'
import {cilDropdownArrow} from './cilDropdownArrow'
import {cilDocUser} from './cilDocUser'
import {cilSolidCheck} from './cilSolidCheck'
import {cilListAdd} from './cilListAdd'
import {cilSolidUser} from './cilSolidUser'
import {cilFolderNew} from './cilFolderNew'
import {cilArrowUpload} from './cilArrowUpload'
import {cilTrashRed} from '@/assets/icons/cilTrashRed'
import {cilPrimaryDoc} from '@/assets/icons/cilPrimaryDoc'
import {cilApprove} from '@/assets/icons/cilApprove'
import {cilReject} from '@/assets/icons/cilReject'
import {cilCrown} from '@/assets/icons/cilCrown'
import {cilSignature} from '@/assets/icons/cilSignature'
import {cilClients} from '@/assets/icons/cilClients'
import {cilWarning as cilWarningFilled} from '@/assets/icons/cilWarning'
import {cilCircleInfo} from '@/assets/icons/cilCircleInfo'
import {cilExclamationCircle} from '@/assets/icons/cilExclamationCircle'
import {cilSync} from '@/assets/icons/cilSync'
import {cilAddBell} from '@/assets/icons/cilAddBell'
import {cilOpenMail} from '@/assets/icons/cilOpenMail'
import {cilExclamationLabel} from "@/assets/icons/cilExclamationLabel";
import {cilSendPlane} from "@/assets/icons/cilSendPlane";
import {cilCrownFilled} from "@/assets/icons/cilCrownFilled";
import {cilClockCalendar} from "@/assets/icons/cilClockCalendar";
import {cilChevronBottomSolid} from '@/assets/icons/cilChevronBottomSolid'
import {cilCrownLabel} from "@/assets/icons/cilCrownLabel";
import {cilAppLogo} from "@/assets/icons/cilAppLogo";

import {
    cibBehance,
    cibCcAmex,
    cibCcApplePay,
    cibCcMastercard,
    cibCcPaypal,
    cibCcStripe,
    cibCcVisa,
    cibDribbble,
    cibFacebook,
    cibFlickr,
    cibGithub,
    cibGoogle,
    cibInstagram,
    cibLinkedin,
    cibPinterest,
    cibReddit,
    cibStackoverflow,
    cibTumblr,
    cibTwitter,
    cibVimeo,
    cibVk,
    cibXing,
    cibYahoo,
    cibYoutube,
    cifBr,
    cifEs,
    cifFr,
    cifIn,
    cifPl,
    cifUs,
    cilAccountLogout,
    cilActionRedo,
    cilActionUndo,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowCircleRight,
    cilArrowCircleTop,
    cilArrowLeft,
    cilArrowRight,
    cilArrowThickBottom,
    cilArrowThickRight,
    cilArrowThickTop,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilBold,
    cilBookmark,
    cilBriefcase,
    cilBuilding,
    cilCalculator,
    cilCalendar,
    cilCalendarCheck,
    cilCaretBottom,
    cilCaretTop,
    cilChart,
    cilChartPie,
    cilChatBubble,
    cilCheck,
    cilCheckAlt,
    cilCheckCircle,
    cilChevronBottom,
    cilChevronDoubleDown,
    cilChevronDoubleUp,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilClipboard,
    cilClock,
    cilCloudDownload,
    cilCloudUpload,
    cilCode,
    cilCommentBubble,
    cilCommentSquare,
    cilCopy,
    cilCursor,
    cilCursorMove,
    cilDataTransferDown,
    cilDelete,
    cilDescription,
    cilDollar,
    cilDoubleQuoteSansLeft,
    cilDrop,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEqualizer,
    cilEuro,
    cilExternalLink,
    cilFaceDead,
    cilFactory,
    cilFile,
    cilFilter,
    cilFlagAlt,
    cilFolder,
    cilFolderOpen,
    cilGlobeAlt,
    cilGrid,
    cilGroup,
    cilHeader,
    cilHome,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilIndustry,
    cilInfo,
    cilItalic,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLevelUp,
    cilLibrary,
    cilLightbulb,
    cilLink,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilLoopCircular,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMinus,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperclip,
    cilPaperPlane,
    cilPen,
    cilPencil,
    cilPenNib,
    cilPeople,
    cilPlus,
    cilPrint,
    cilPuzzle,
    cilReload,
    cilSave,
    cilSearch,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilStorage,
    cilSun,
    cilTag,
    cilTags,
    cilTask,
    cilTextSize,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilWarning,
    cilWindowRestore,
    cilX,
    cilXCircle
} from '@coreui/icons'

export const iconsSet = {
    cilTextSize,
    cilExternalLink,
    cilCrownLabel,
    cilChevronBottomSolid,
    cilCrownFilled,
    cilClockCalendar,
    cilSendPlane,
    cilExclamationLabel,
    cilFaceDead,
    cilIndustry,
    cilOpenMail,
    cilAddBell,
    cilSync,
    cilWarningFilled,
    cilClients,
    cilSignature,
    cilCalendarCheck,
    cilArrowCircleTop,
    cilLoopCircular,
    cilDropdownArrow,
    cilChatBubble,
    cilFlagAlt,
    cilFileCheck,
    cilListAdd,
    cilSolidCheck,
    cilSolidUser,
    cilDocUser,
    cilActionLeft,
    cilUploadPlace,
    cilArrowUp,
    cilArrowDown,
    cilArBottomBlue,
    cilArTopBlue,
    cilArRight,
    cilArBottomBlack,
    cilInfo,
    cilInfoCircle,
    cilAlignCenter,
    cilAlignLeft,
    cilAlignRight,
    cilApplicationsSettings,
    cilArrowBottom,
    cilArrowRight,
    cilArrowTop,
    cilBan,
    cilBasket,
    cilBell,
    cilBookmark,
    cilBold,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronLeft,
    cilChevronRight,
    cilChevronTop,
    cilCheckCircle,
    cilCode,
    cilCommentSquare,
    cilCompanies,
    cilCursor,
    cilDrop,
    cilDocument,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeLetter,
    cilEnvelopeOpen,
    cilEuro,
    cilFile,
    cilGlobeAlt,
    cilGrid,
    cilInbox,
    cilIndentDecrease,
    cilIndentIncrease,
    cilItalic,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLightbulb,
    cilList,
    cilListNumbered,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMediaPlay,
    cilMenu,
    cilMoon,
    cilNotes,
    cilOptions,
    cilPaperPlane,
    cilPaperclip,
    cilPencil,
    cilPeople,
    cilPrint,
    cilProjects,
    cilPuzzle,
    cilSave,
    cilSettings,
    cilShare,
    cilShareAll,
    cilShareBoxed,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilSpreadsheet,
    cilStar,
    cilSun,
    cilTags,
    cilTag,
    cilTask,
    cilTrash,
    cilUnderline,
    cilUser,
    cilUsers,
    cilUserFemale,
    cilUserFollow,
    cilUserUnfollow,
    cilXCircle,
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl,
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibGoogle,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibCcStripe,
    cibCcPaypal,
    cibCcApplePay,
    cibCcAmex,
    cilSearch,
    cilFilter,
    cilChart,
    cilClock,
    cilGroup,
    cilFolder,
    cilPlus,
    cilChevronDoubleDown,
    cilChevronDoubleUp,
    cilCloudUpload,
    cilCheckAlt,
    cilLevelUp,
    cilX,
    cilWindowRestore,
    cilCaretBottom,
    cilCaretTop,
    cilHome,
    cilFactory,
    cilCopy,
    cilFolderOpen,
    cilLink,
    cilPen,
    cilReload,
    cilStorage,
    cilBuilding,
    cilBriefcase,
    cilClipboard,
    cilTasks,
    cilMinus,
    cilActionRedo,
    cilActionUndo,
    cilHeader,
    cilDescription,
    cilArrowThickRight,
    cilArrowThickTop,
    cilArrowThickBottom,
    cilPenNib,
    cilLibrary,
    cilDelete,
    cilDataTransferDown,
    cilCursorMove,
    cilArrowLeft,
    cilInfoSquare,
    cilCommentBubble,
    cilDoubleQuoteSansLeft,
    cilAccountLogout,
    cilReplaceFile,
    cilEqualizer,
    cilFolderNew,
    cilArrowUpload,
    cilTrashRed,
    cilPrimaryDoc,
    cilApprove,
    cilReject,
    cilWarning,
    cilArrowCircleRight,
    cilCrown,
    cilCircleInfo,
    cilExclamationCircle,
    cilAppLogo
}
