<template>
    <div style="position: relative; padding: 0">
        <div class="SmartTable-setting">
            <CIcon name="cilSettings" @click="colVis = true"/>
            <div v-if="colVis" class="SmartTable-setting-list" @mouseleave="colVis = false">
                <div class="SmartTable-setting-title">Show/Hide columns</div>
                <div v-for="(column, index) in columns.filter((i) => !!i.label)" :key="`setting-${column.key}-${index}`"
                     class="SmartTable-setting-list-item">
                    <CFormCheck :checked="visible[column.key]" @click="setVisible(column.key)"/>
                    {{ column.label }}
                </div>
            </div>
        </div>
        <div :class="{ SmartTable: true, infinityScroll: infinityScroll }" @scroll="onScroll">
            <table :style="loading ? `min-height: 200px` : ''" class="SmartTable-table-el">
                <thead>
                <tr>
                    <th v-if="select" style="max-width: 14px; width: 14px">
                        <div>
                            <CFormCheck v-if="!dd"
                                        :checked=" selectedItems.length !== 0 && selectedItems.length === items.length"
                                        :indeterminate=" selectedItems.length > 0 && selectedItems.length !== items.length"
                                        @click="setSelectedAll()"/>
                        </div>
                    </th>
                    <template v-for="(column, index) in columns" :key="`${column.key}-${index}`">
                        <th v-if="visible[column.key]" :id="'SmartTable-id-' + column.key" :style="column._style">
                            <div class="d-flex align-items-center SmartTable-header w-100">
                                <Popper :content="column.label" class="SmartTable-label">{{ column.label }}</Popper>
                                <div v-if=" typeof column.sorter == 'undefined' || !!column.sorter"
                                     class="d-flex flex-column SmartTable-sort">
                                    <img src="@/assets/sort-up.svg"
                                         :style="`opacity: ${ sorterValue.state === 'desc' && sorterValue.column === column.key ? 1 : 0.5 }`"
                                         @click="setSort({ column: column.key, state: 'desc' })"/>
                                    <img src="@/assets/sort-down.svg"
                                         :style="`opacity: ${ sorterValue.state === 'asc' && sorterValue.column === column.key ? 1 : 0.5 }`"
                                         @click="setSort({ column: column.key, state: 'asc' })"/>
                                </div>
                            </div>
                        </th>
                    </template>
                    <th v-if="!!$slots.button"/>
                </tr>
                </thead>
                <tbody v-if="loading && !infinityScroll" style="position: relative">
                <div class="SmartTable-loading">
                    <CSpinner color="primary"/>
                </div>
                </tbody>
                <tbody v-else-if="!!$slots.clear_text && !items.length">
                <tr>
                    <td colspan="50">
                        <slot name="clear_text"/>
                    </td>
                </tr>
                </tbody>
                <tbody v-else-if="!items.length">
                <tr>
                    <td :colspan="columns.length + 1">
                        <div class="SmartTable-clear">
                            {{ clearText || 'No Data yet' }}
                        </div>
                    </td>
                </tr>
                </tbody>
                <tbody v-else-if="dd">
                <template v-for="(dd, ddIndex) in items" :key="ddIndex">
                    <tr :class="{ open: ddOpen.findIndex((i) => i === ddIndex) !== -1 }" v-bind="{ ...dd.bind }"
                        @click="setDDOpen(ddIndex)" @dragenter.prevent @dragover.prevent
                        @drop="dropHandler($event, dd)">
                        <td colspan="50">
                            <div class="SmartTable-col dd SmartTable-dropdown">
                                <div class="SmartTable-dropdown-item">
                                    {{ dd.title }}
                                    <CIcon v-if="ddOpen.findIndex((i) => i === ddIndex) === -1" name="cilArBottomBlue"
                                           class="ml-2" size="sm"/>
                                    <CIcon v-else name="cilArTopBlue" class="ml-2" size="sm"/>
                                </div>

                                <div v-if="dd.additionalAction" class="SmartTable-dropdown-item">
                                    <CButton size="sm" color="link" style="color: #0068ad"
                                             @click.stop=" emitAdditionalDropdownEvent( dd.additionalAction.buttonEvent, )">
                                        {{ dd.additionalAction.buttonText }}
                                    </CButton>
                                </div>
                            </div>
                        </td>
                    </tr>

                    <template v-if="ddOpen.findIndex((i) => i === ddIndex) !== -1">
                        <template v-for="(item, index) in dd.items" :key="`${index}`">
                            <tr :style="`background: ${ selectedLine && selectedLine(item) ? '#ccc' : '' }`"
                                :class="{ 'grey-tr': index % 2 === 0 }" :draggable="draggable"
                                v-bind="{ ...dd.bind, 'data-id': item.template_id }" @click="$emit('row-click', item)"
                                @drop="dropHandler($event, dd)" @dragenter.prevent @dragover.prevent
                                @dragstart="dragHandler($event, item)">
                                <td v-if="select">
                                    <div class="SmartTable-col" style="min-width: 1px">
                                        <CFormCheck
                                            :checked=" selectedItems.findIndex( (i) => i === item[selectable], ) !== -1"
                                            @click="setSelected(item)"/>
                                    </div>
                                </td>
                                <template v-for="(column, index2) in columns" :key="`${column.key}-${index}-${index2}`">
                                    <td v-if="visible[column.key]">
                                        <div :class="`SmartTable-col SmartTable-id-${column.key}`"
                                             :style="column._style">
                                            <slot :name="column.key" :item="{ ...item, _id: index }" :column="column">
                                                <router-link v-if="column.link?.iconNewTab"
                                                             :to="{ name: column.link.name, params: getParams(column.link.params, item), query: column.link.query }"
                                                             target="_blank"
                                                             class="SmartTable-col-content SmartTable-col-content-link">
                                                    <CIcon
                                                        :name="typeof column.link.iconNewTab === 'string' ? column.link.iconNewTab : 'cilWindowRestore'"/>
                                                </router-link>
                                                <Popper
                                                    v-if=" column.format ? column.format(item[column.key], item) : item[column.key]"
                                                    :content=" column.format ? column.format(item[column.key], item) : item[column.key]">
                                                    <a v-if="column.email"
                                                       :href="`${ typeof column.email == 'string' ? column.email : 'mailto' }:${item[column.key]}`"
                                                       class="SmartTable-col-content">
                                                        {{ item[column.key] }}
                                                    </a>
                                                    <a v-else-if="column.click"
                                                       class="SmartTable-col-content SmartTable-col-content-link"
                                                       @click="column.click(item)">
                                                        {{
                                                            column.format ? column.format(item[column.key], item) : item[column.key]
                                                        }}
                                                    </a>
                                                    <router-link v-else-if="column.link"
                                                                 :to="{ name: column.link.name, params: getParams(column.link.params, item), query: column.link.query, }"
                                                                 class="SmartTable-col-content SmartTable-col-content-link">
                                                        {{
                                                            column.format ? column.format(item[column.key], item) : item[column.key]
                                                        }}
                                                    </router-link>
                                                    <div v-else class="SmartTable-col-content">
                                                        {{
                                                            column.format ? column.format(item[column.key], item) : item[column.key]
                                                        }}
                                                    </div>
                                                </Popper>
                                                <span v-else>N/A</span>
                                                <div class="SmartTable-col-action">
                                                    <slot v-if="!!$slots.status" name="status" :item="item"
                                                          :column="column"/>
                                                    <Popper v-if="column.tags" :hide="!item[column.tags]"
                                                            :content="item[column.tags]">
                                                        <CIcon name="cilTags"/>
                                                    </Popper>
                                                </div>
                                            </slot>
                                        </div>
                                    </td>
                                </template>
                                <td v-if="!!$slots.button">
                                    <slot name="button" :item="item"/>
                                </td>
                            </tr>
                            <tr v-if="$slots.details_accordion" class="details-accordion">
                                <td colspan="50">
                                    <slot name="details_accordion" :item="{ ...item, _id: index }"/>
                                </td>
                            </tr>
                        </template>
                        <tr v-if="!dd.items?.length" v-bind="{ ...dd.bind }" @drop="dropHandler($event, dd)"
                            @dragenter.prevent @dragover.prevent>
                            <td :colspan="50">
                                <div class="SmartTable-clear" style="min-height: 50px">
                                    <slot name="dd_clear_text" :item="dd">
                                        {{ clearText || 'No Data yet' }}
                                    </slot>
                                </div>
                            </td>
                        </tr>
                    </template>
                </template>
                <tr v-if="loading && infinityScroll" style="position: relative">
                    <div class="SmartTable-loading" style="height: 130px">
                        <CSpinner color="primary"/>
                    </div>
                </tr>
                </tbody>
                <tbody v-else>
                <template v-for="(item, index) in items" :key="`${index}`">
                    <tr :style="`background: ${ selectedLine && selectedLine(item) ? '#ccc' : '' }`"
                        class="SmartTable__row" :class="{ 'grey-tr': index % 2 === 0 }" :draggable="draggable"
                        @click="$emit('row-click', item)" @dragstart="dragHandler($event, item)" @dragenter.prevent
                        @dragover.prevent>
                        <td v-if="select">
                            <div class="SmartTable-col" style="min-width: 1px">
                                <CFormCheck :checked=" selectedItems.findIndex((i) => i === item[selectable]) !== -1"
                                            @click="setSelected(item)"/>
                            </div>
                        </td>
                        <template v-for="(column, index2) in columns" :key="`${column.key}-${index}-${index2}`">
                            <td v-if="visible[column.key]">
                                <div :class="`SmartTable-col SmartTable-id-${column.key}`" :style="column._style">
                                    <slot :name="column.key" :item="{ ...item, _id: index }" :column="column">
                                        <router-link v-if="column.link?.iconNewTab"
                                                     :to="{ name: column.link.name, params: getParams(column.link.params, item), query: column.link.query }"
                                                     target="_blank"
                                                     class="SmartTable-col-content SmartTable-col-content-link">
                                            <CIcon
                                                :name=" typeof column.link.iconNewTab === 'string' ? column.link.iconNewTab : 'cilWindowRestore'"/>
                                        </router-link>
                                        <a v-if="column.click && column.iconNewTab"
                                           class="SmartTable-col-content SmartTable-col-content-link"
                                           @click="column.click(item, true)">
                                            <CIcon
                                                :name=" typeof column.iconNewTab === 'string' ? column.iconNewTab : 'cilWindowRestore'"/>
                                        </a>
                                        <Popper
                                            v-if=" column.format ? column.format(item[column.key], item) : item[column.key]"
                                            :content=" column.format ? column.format(item[column.key], item) : item[column.key]">
                                            <a v-if="column.email"
                                               :href="`${ typeof column.email == 'string' ? column.email : 'mailto' }:${item[column.key]}`"
                                               class="SmartTable-col-content">
                                                {{ item[column.key] }}
                                            </a>
                                            <a v-else-if="column.click"
                                               class="SmartTable-col-content SmartTable-col-content-link"
                                               @click="column.click(item)">
                                                {{
                                                    column.format ? column.format(item[column.key], item) : item[column.key]
                                                }}
                                            </a>
                                            <router-link v-else-if="column.link"
                                                         :to="{ name: column.link.name, params: getParams(column.link.params, item), query: column.link.query, }"
                                                         class="SmartTable-col-content SmartTable-col-content-link">
                                                {{
                                                    column.format ? column.format(item[column.key], item) : item[column.key]
                                                }}
                                            </router-link>
                                            <div v-else class="SmartTable-col-content">
                                                {{
                                                    column.format ? column.format(item[column.key], item) : item[column.key]
                                                }}
                                            </div>
                                        </Popper>
                                        <span v-else>N/A</span>
                                        <div class="SmartTable-col-action">
                                            <slot v-if="!!$slots.status" name="status" :item="item" :column="column"/>
                                            <Popper v-if="column.tags" :hide="!item[column.tags]"
                                                    :content="item[column.tags]">
                                                <CIcon name="cilTags"/>
                                            </Popper>
                                        </div>
                                    </slot>
                                </div>
                            </td>
                        </template>
                        <td v-if="!!$slots.button">
                            <slot name="button" :item="item"/>
                        </td>
                    </tr>
                    <tr v-if="$slots.details_accordion" class="details-accordion">
                        <td colspan="50">
                            <slot name="details_accordion" :item="{ ...item, _id: index }"/>
                        </td>
                    </tr>
                </template>
                <tr v-if="loading && infinityScroll" style="position: relative">
                    <div class="SmartTable-loading" style="height: 130px">
                        <CSpinner color="primary"/>
                    </div>
                </tr>
                </tbody>
            </table>
        </div>
        <CPagination v-if="!infinityScroll && !dd" :model-value="activePage" :per-page="perPage"
                     :update-per-page="(e) => $emit('update-per-page', e)" :pages="pages"
                     @update:modelValue="(e) => $emit('update-active-page', e)"/>
    </div>
</template>

<script>
import CPagination from '@/components/CPagination'

export default {
    components: {CPagination},
    props: {
        columns: {},
        items: {},
        sorterValue: {default: {}},
        selectable: {},
        selectedItems: {default: () => []},
        loading: {},
        activePage: {},
        pages: {},
        perPage: {},
        clearText: {},
        infinity: {},
        tableName: {},
        selectedLine: {},
        dropdown: {},
        draggable: {type: Boolean, default: false},
    },
    emits: [
        'sorterChange',
        'selectedItemsChange',
        'update-active-page',
        'update-per-page',
        'infinity-update',
        'row-click',
        'event-drag',
        'event-drop',
        'event-drag-enter',
        'additionalDropdownEvent',
        'event-drag-transfer',
        'event-drop-transfer',
        'event-drop-upload',
    ],
    data: () => ({
        visible: {},
        colVis: false,
        ddOpen: [0, 1, 2],
    }),
    computed: {
        select() {
            return typeof this.selectable != 'undefined'
        },
        infinityScroll() {
            return typeof this.infinity != 'undefined'
        },
        dd() {
            return typeof this.dropdown != 'undefined'
        },
        user() {
            return this.$store.getters['user']
        },
    },
    watch: {
        'columns.length'() {
            this.fetchColumns()
        },
        'items.length'() {
            this.updateTableResize()
        },
    },
    mounted() {
        this.fetchColumns()
    },
    methods: {
        setDDOpen(index) {
            let i = this.ddOpen.findIndex((i) => i === index)
            if (i === -1) {
                this.ddOpen.push(index)
            } else {
                this.ddOpen.splice(i, 1)
            }
        },
        setSort(column) {
            if (
                column.state === this.sorterValue.state &&
                column.column === this.sorterValue.column
            ) {
                this.$emit('sorterChange', {column: '', state: ''})
            } else {
                this.$emit('sorterChange', column)
            }
        },
        onScroll({target: {scrollTop, clientHeight, scrollHeight}}) {
            if (scrollTop + clientHeight >= scrollHeight && !this.loading) {
                this.$emit('infinity-update')
            }
        },
        setVisible(key) {
            this.visible[key] = !this.visible[key]
            localStorage.setItem(
                `CC-${this.tableName || this.$route.name}-${this.user.id}`,
                JSON.stringify(this.visible),
            )
            this.updateTableResize()
        },
        fetchColumns() {
            this.visible = {}
            let def = {}
            try {
                def = JSON.parse(
                    localStorage.getItem(`CC-${this.tableName || this.$route.name}-${this.user.id}`),
                )
            } catch {
                def = {}
            }
            this.columns.map((i) => {
                if (def && typeof def[i.key] != 'undefined') {
                    this.visible[i.key] = !!def[i.key]
                } else {
                    this.visible[i.key] = true
                }
            })
            this.updateTableResize()
        },
        updateTableResize() {
            document
                .querySelectorAll('.SmartTable-table-el-hover-line')
                .forEach((i) => i.remove())

            var tables = document.getElementsByClassName('SmartTable-table-el')
            for (var i = 0; i < tables.length; i++) {
                resizableGrid(tables[i])
            }

            function resizableGrid(table) {
                var row = table.getElementsByTagName('tr')[0],
                    cols = row ? row.children : undefined

                if (!cols) return

                for (var i = 0; i < cols.length - 1; i++) {
                    var div = createDiv()
                    cols[i].appendChild(div)
                    setListeners(div)
                }

                function setListeners(div) {
                    var pageX,
                        curCol,
                        nxtCol,
                        curCols = [],
                        nxtCols = [],
                        curColWidth,
                        nxtColWidth,
                        curColsWidth = [],
                        nxtColsWidth = []

                    div.addEventListener('mousedown', function (e) {
                        curCol = e.target.parentElement
                        nxtCol = curCol.nextElementSibling
                        pageX = e.pageX

                        var padding = paddingDiff(curCol)

                        curColWidth = curCol.offsetWidth - padding
                        if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding

                        document.querySelectorAll(`.${curCol.id}`).forEach((i) => {
                            let curCol = i.parentElement.children[0]
                            let nxtCol = i.parentElement.nextElementSibling.children[0]

                            let padding = paddingDiff(curCol)

                            curColsWidth.push(curCol.offsetWidth - padding)
                            if (nxtCol) nxtColsWidth.push(nxtCol.offsetWidth - padding)

                            curCols.push(curCol)
                            nxtCols.push(nxtCol)
                        })
                    })

                    div.addEventListener('mouseover', function (e) {
                        e.target.style.borderRight = '2px solid #ffffff'
                    })

                    div.addEventListener('mouseout', function (e) {
                        e.target.style.borderRight = ''
                    })

                    document.addEventListener('mousemove', function (e) {
                        if (curCol) {
                            var diffX = e.pageX - pageX

                            if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + 'px'

                            curCol.style.width = curColWidth + diffX + 'px'

                            document.querySelectorAll(`.${curCol.id}`).forEach((i) => {
                                curCols.map((el, index) => {
                                    if (curCols[index]) {
                                        var diffX = e.pageX - pageX

                                        if (nxtCols[index])
                                            nxtCols[index].style.width = nxtColWidth - diffX + 'px'

                                        curCols[index].style.width = curColWidth + diffX + 'px'
                                    }
                                })
                            })
                        }
                    })

                    document.addEventListener('mouseup', function (e) {
                        curCol = undefined
                        nxtCol = undefined
                        pageX = undefined
                        nxtColWidth = undefined
                        curColWidth = undefined
                    })
                }

                function createDiv() {
                    var div = document.createElement('div')
                    div.style.top = 0
                    div.style.right = 0
                    div.style.width = '5px'
                    div.style.position = 'absolute'
                    div.style.cursor = 'col-resize'
                    div.style.userSelect = 'none'
                    div.style.height = 44 + 'px'
                    div.classList = 'hover-line SmartTable-table-el-hover-line'
                    return div
                }

                function paddingDiff(col) {
                    if (getStyleVal(col, 'box-sizing') == 'border-box') {
                        return 0
                    }

                    var padLeft = getStyleVal(col, 'padding-left')
                    var padRight = getStyleVal(col, 'padding-right')
                    return parseInt(padLeft) + parseInt(padRight)
                }

                function getStyleVal(elm, css) {
                    return window.getComputedStyle(elm, null).getPropertyValue(css)
                }
            }
        },
        getParams(params, item) {
            let p = {}
            Object.keys(params).forEach((key) => {
                p[key] = item[params[key]]
            })
            return p
        },
        setSelected(item) {
            const arr = this.selectedItems.includes(item[this.selectable])
                ? this.selectedItems.filter((i) => i !== item[this.selectable])
                : [item[this.selectable], ...this.selectedItems]

            this.$emit('selectedItemsChange', arr)
        },
        setSelectedAll() {
            if (this.selectedItems.length === this.items.length) {
                this.$emit('selectedItemsChange', [])
            } else {
                this.$emit(
                    'selectedItemsChange',
                    this.items.map((i) => i[this.selectable]),
                )
            }
        },
        dragHandler(event, item) {
            if (!this.draggable) return
            this.$emit('event-drag', event)
            this.$emit('event-drag-transfer', {event, item})
        },
        dropHandler(event, item) {
            if (!this.draggable) return

            if (event.dataTransfer.files.length) {
                event.preventDefault()
                this.$emit('event-drop-upload', {event, item})
            } else {
                this.$emit('event-drop-transfer', {event, item})
            }

            this.$emit('event-drop', event)
        },

        emitAdditionalDropdownEvent(name) {
            this.$emit('additionalDropdownEvent', name)
        },
    },
}
</script>

<style lang="scss">
@import 'style';

th {
    &:hover > .hover-line {
        border-right: 2px solid #d8d8d8 !important;

        &:hover {
            border-right: 2px solid #d8d8d8 !important;
        }
    }
}
</style>
