import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import ApiService, {getVerUri} from '@/services/ApiService';
import * as Sentry from '@sentry/vue';

import VueGtm from 'vue-gtm';
import posthog from '@/plugins/posthog';
import CoreuiVue from '@coreui/vue-pro';
import CIcon from '@coreui/icons-vue';
import COverdueTooltip from '@/components/COverdueTooltip';
import {iconsSet as icons} from '@/assets/icons';
import DocsCallout from '@/components/DocsCallout';
import DocsExample from '@/components/DocsExample';
import cc from '@/components/global-components';
import {BtoF, DwT, FtoB, luxon, timeByCurrentTimeZone} from '@/services/MomentService';
import {formatMobile} from '@/services/FormatService';
import {IsMobile} from '@/services/IsMobile';
import {VMoney} from 'v-money';
import objectPicker from '@/utils/plugins/objectPicker';
import authUserMixin from '@/mixin/user';
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';
import {Loading} from './directives/loader';
import {Autosize} from './directives/autosize';
import {CustomEventOnScroll} from './directives/customEventOnScroll';
import Popper from '@/components/Popper';
import SmartTable from '@/components/SmartTable';
import SmartTableButton from '@/components/SmartTable/Button';
import {ShortcodesSuggestion} from './directives/shortcodesSuggestion/shortcodesSuggestion';
import LaravelEcho from './services/LaravelEcho';
import EventBus from './services/EventBus';
import cnComponents from './components/ui/cnComponents';
import currency from 'currency.js';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

String.prototype.capitalize = function () {
    return this.split('_')
        .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
        .join(' ')
};

Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
};

const app = createApp(App);

app.config.unwrapInjectedRef = true;
app.config.globalProperties.$log = console.log.bind(console);
app.config.globalProperties.$http = ApiService.api;
app.config.globalProperties.axios = ApiService._axios;

app.config.globalProperties.$eventSource = (url, params = {}) => {
    return new EventSource(process.env.VUE_APP_API_URL + getVerUri(url), params);
};

app.config.globalProperties.$BtoF = BtoF;
app.config.globalProperties.$FtoB = FtoB;
app.config.globalProperties.$notify = (data) => store.commit('setToast', data);
app.config.globalProperties.$DwT = DwT;
app.config.globalProperties.$luxon = luxon;
app.config.globalProperties.$TCurrentZone = timeByCurrentTimeZone;
app.config.globalProperties.$currency = currency;
app.config.globalProperties.$formatMobile = formatMobile;
app.config.globalProperties.$isMobile = IsMobile;
app.config.globalProperties.$LaravelEcho = LaravelEcho;
app.config.globalProperties.$EventBus = EventBus;
app.config.globalProperties.$deepClone = (v) => JSON.parse(JSON.stringify(v));

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        app,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

if (process.env.VUE_APP_GTM_ID) {
    app.use(VueGtm, {
        id: process.env.VUE_APP_GTM_ID,
        enabled: true,
        vueRouter: router,
    });
}

if (process.env.VUE_APP_POSTHOG_APP_KEY) {
    app.use(posthog);
}

app.use(objectPicker);
app.use(store);
app.use(router);
app.use(i18n);
app.use(CoreuiVue);
app.provide('icons', icons);
app.directive('money', VMoney);
app.component('CIcon', CIcon);
app.use(FloatingVue);
app.component('COverdueTooltip', COverdueTooltip);
app.component('DocsCallout', DocsCallout);
app.component('DocsExample', DocsExample);
app.component('CInput', cc.CInput);
app.component('VPagination', VPagination);
app.component('SmartTable', SmartTable);
app.component('SmartTableButton', SmartTableButton);
app.component('Popper', Popper);

Object.entries(cnComponents).forEach(([name, component]) => app.component(name, component));

app.mixin(authUserMixin);

app.directive('loading', Loading);
app.directive('autosize', Autosize);
app.directive('custom-event-on-scroll', CustomEventOnScroll);
app.directive('with-shortcodes-suggestion', ShortcodesSuggestion);

app.mount('#app');
