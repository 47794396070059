import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    archiveUser: (data) => asJson(axios).post(getVerUri(`users/${data.user_id}/archive`), data),
    getProjects: (data) => asJson(axios).get(getVerUri('users/projects'), {params: data}),
    getTasks: (data) => asJson(axios).get(getVerUri('users/tasks'), {params: data}),
    exportUsers: (data) => axios({
        method: 'GET',
        url: getVerUri('users/export'),
        responseType: 'blob',
        params: data,
    }),
})
