import $p from '@/utils/constants/permissions'
import {canEdit as editEmployee, canListView,} from './middleware/employee'
import {canEdit, canEditProjects, canEditTemplates, canViewAccountDetails, canViewHome,} from './middleware/setting'

const Settings = () => import('@/views/Setting')
const SettingsList = () => import('@/views/Setting/SettingList')
const SettingsAccount = () => import('@/views/Setting/Account')
const AccountDetails = () => import('@/views/Setting/Account/AccountDetails')
const CompanyDetails = () => import('@/views/Setting/Account/CompanyDetails')
const SettingsEmployees = () => import('@/views/Setting/Account/CompanyUsers')
const CompanyUsersEdit = () => import('@/views/Setting/Account/CompanyUsers/CompanyUsersEdit')
const CompanyUsersIndex = () => import('@/views/Setting/Account/CompanyUsers/CompanyUsersIndex')
const InvitedEmployees = () => import('@/views/Setting/Account/CompanyUsers/InvitedEmployees')
const PermissionGroup = () => import('@/views/Setting/Account/PermissionGroup')
const SettingsTemplates = () => import('@/views/Setting/Templates')
const SettingDocumentTemplates = () => import('@/views/Setting/Templates/Document')
const SettingEmailTemplates = () => import('@/views/Setting/Templates/Email')
const SettingsProject = () => import('@/views/Setting/Project')
const CompanyTypes = () => import('@/views/Setting/Project/CompanyTypes')
const ContractApproval = () => import('@/views/Setting/Project/ContractApproval')
const ScopeApproval = () => import('@/views/Setting/Project/ScopeApproval')

export default [
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter: async (to) => await canViewHome(to),
        redirect: {
            name: 'SettingsList',
        },
        children: [
            {
                path: '',
                name: 'SettingsList',
                component: SettingsList,
            },
            {
                path: 'account',
                name: 'SettingsAccount',
                redirect: {
                    name: 'SettingAccountDetails',
                },
                component: SettingsAccount,
                children: [
                    {
                        path: 'account-details',
                        name: 'SettingAccountDetails',
                        component: AccountDetails,
                        beforeEnter: async () => await canViewAccountDetails(),
                    },
                    {
                        path: 'company-details',
                        name: 'SettingCompanyDetails',
                        component: CompanyDetails,
                        meta: {
                            permissions: [$p.COMPANIES_VIEW, $p.ACCOUNT_CONFIGURATION_VIEW],
                            checkType: 'any',
                        },
                    },
                    {
                        path: 'company-users',
                        name: 'SettingEmployees',
                        redirect: {
                            name: 'SettingCompanyUsersIndex',
                        },
                        component: SettingsEmployees,
                        children: [
                            {
                                path: '/my-profile',
                                name: 'MyProfile',
                                component: CompanyUsersEdit,
                            },
                            {
                                path: '',
                                name: 'SettingCompanyUsersIndex',
                                component: CompanyUsersIndex,
                                beforeEnter: async () => await canListView(),
                            },
                            {
                                path: 'invited-employees',
                                name: 'InvitedEmployees',
                                component: InvitedEmployees,
                                beforeEnter: async () => await canListView(),
                            },
                            {
                                path: ':id',
                                name: 'SettingCompanyUsersEdit',
                                component: CompanyUsersEdit,
                                beforeEnter: async (to) => await editEmployee(to),
                            },
                        ],
                    },
                    {
                        path: 'permission-group',
                        name: 'SettingPermissionGroups',
                        component: PermissionGroup,
                    },
                ],
            },
            {
                path: 'templates',
                name: 'SettingsTemplates',
                redirect: {
                    name: 'SettingDocumentTemplate',
                },
                beforeEnter: async () => await canEditTemplates(),
                component: SettingsTemplates,
                children: [
                    {
                        path: 'document-templates',
                        name: 'SettingDocumentTemplate',
                        component: SettingDocumentTemplates,
                    },
                    {
                        path: 'email-templates',
                        name: 'SettingEmailTemplates',
                        component: SettingEmailTemplates,
                    },
                ],
            },
            {
                path: 'project',
                name: 'SettingsProject',
                component: SettingsProject,
                redirect: {
                    name: 'SettingContractApprovals',
                },
                children: [
                    {
                        path: 'contract-approval',
                        name: 'SettingContractApprovals',
                        component: ContractApproval,
                        beforeEnter: async () => await canEditProjects(),
                    },
                    {
                        path: 'scope-approval',
                        name: 'SettingScopeApproval',
                        component: ScopeApproval,
                        beforeEnter: async () => await canEditProjects(),
                    },
                    {
                        path: 'commitment-types',
                        name: 'SettingCompanyTypes',
                        component: CompanyTypes,
                        beforeEnter: async () => await canEdit(),
                    },
                ],
            },
        ],
    },
]
