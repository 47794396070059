<template>
  <CRow id="GCFilter" ref="GCFilter" :xs="{ cols: 1, gutter: 2 }">
    <CCol class="p-0" style="z-index: 999 !important">
      <CIcon name="cilSearch" class="filterSearchIcon" />
      <CFormInput v-model="form.search" class="inputControl" placeholder="Search" @input="handleInput($event)" />
      <CButton v-if="!isDisabled || form.search" class="me-3 btnX" variant="ghost" @click="handleRemove([])">
        <CIcon name="cilX" />
      </CButton>
      <CButton class="me-3 btnControl" variant="ghost" @click="toggleSearchBody" v-if="advancedSearch">
        <CIcon name="cilEqualizer" />
      </CButton>

      <Badges v-if="badges.length" class="mt-2" :badges="badges" @remove="handleRemove" />

      <CCard v-show="isOpen" class="mt-0 searchBody">
        <CCardBody>
          <component :is="component" ref="itemType" :except-column="exceptColumn" @updated="updated" />
          <CCol class="mt-4" style="text-align: right">
            <CButton color="primary" variant="outline" class="mr-3" :disabled="isDisabled" @click="handleRemove([])">
              Reset
            </CButton>
            <CButton class="GCFilterSearch" color="primary" :disabled="isDisabled" @click="handleSearch">
              Search
            </CButton>
          </CCol>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script src="./script.js"></script>
<style lang="scss">
@import 'style';
</style>
