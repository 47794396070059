export default {
    app: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        maintenanceMode: !!process.env.VUE_APP_MAINTENANCE_MODE,
    },
    api: {
        baseUrl: process.env.VUE_APP_BASE_API_URL,
    },
    services: {
        apryse: {
            licenseKey: process.env.VUE_APP_APRYSE_LICENSE_KEY,
        }
    }
}
