import i18n from '../../i18n'
import router from '../../router'
import store from '../../store'

export const updatePlan = {
    type: 'warning',
    title: i18n.global.t('toasts.updatePlan.title'),
    content: i18n.global.t('toasts.updatePlan.content'),
    buttons: [
        {
            text: i18n.global.t('toasts.updatePlan.btn'),
            color: 'primary',
            variant: 'outline',
            callback: () => router.push({name: 'SettingAccountDetails'}),
        },
    ],
}

export const permissionError = {
    type: 'info',
    title: i18n.global.t('toasts.requestPermission.title'),
    content: i18n.global.t('toasts.requestPermission.content'),
    buttons: [
        {
            text: i18n.global.t('toasts.requestPermission.btn'),
            color: 'primary',
            variant: 'outline',
            callback: () => true,
        },
    ],
}

export const notifyByPlan = () => {
    if (store.getters.isSubPlan) {
        store.commit('setToast', updatePlan)
    }

    if (store.getters.isGeneralPlan) {
        store.commit('setToast', permissionError)
    }
}
