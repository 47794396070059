<template>
    <div :class="{ 'c-dark-theme': $store.state.darkMode }" class="c-app">
        <CContainer class="d-flex align-items-center min-vh-100">
            <CRow class="w-100 justify-content-center">
                <CCol md="6">
                    <div class="w-100">
                        <div class="clearfix">
                            <h4 class="pt-3">
                                Sorry, this link has expired.
                            </h4>
                            <p class="text-muted">
                                <LoadingButton color="primary" @click="$router.push({ name: 'Login' })">
                                    Go to login page
                                </LoadingButton>
                            </p>
                        </div>
                    </div>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
import LoadingButton from "@/components/LoadingButton.vue";

export default {
    components: {LoadingButton}
}
</script>

<style lang="scss" scoped>
.expired {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
