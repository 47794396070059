import alpha from './alpha'
import digits from './digits'
import digitsPhone from './digitsPhone'
import email from './email'
import nullable from './nullable'
import number from './number'
import numMax from './numMax'
import numMin from './numMin'
import password from './password'
import passwordConfirmation from './passwordConfirmation'
import required from './required'
import requiredIf from './requiredIf'
import strMax from './strMax'
import strMin from './strMin'
import strSize from './strSize'
import username from './username'
import withoutSymbols from './withoutSymbols'
import emails from './emails'
import shortCodePlaceholder from './shortCode/shortCodePlaceholder'
import url from './url'
import extensions from './extensions'
import fileSize from './fileSize'
import unique from './unique'
import inArray from './inArray'
import strMinPhone from './strMinPhone'
import exceptCharacters from "./exceptCharacters";

export default {
    alpha,
    digits,
    digitsPhone,
    email,
    emails,
    exceptCharacters,
    extensions,
    fileSize,
    inArray,
    nullable,
    number,
    numMax,
    numMin,
    password,
    passwordConfirmation,
    required,
    requiredIf,
    shortCodePlaceholder,
    strMax,
    strMin,
    strMinPhone,
    strSize,
    unique,
    url,
    username,
    withoutSymbols,
}
