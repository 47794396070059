import {asFormData, asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    contractsApprovalWorkflowList: (data) => asJson(axios).get(getVerUri('settings/contract/approval-workflow'), data),
    scopesApprovalWorkflowList: (data) => asJson(axios).get(getVerUri('settings/scope/approval-workflow'), data),
    contractsApprovalRounds: (id) => asJson(axios).get(getVerUri('settings/contract/approval-workflow/' + id)),
    createContractsApprovalRounds: (data) => asJson(axios).post(getVerUri('settings/contract/approval-workflow'), data),
    copyContractsApproval: (data) => asJson(axios).post(getVerUri('settings/contract/approval-workflow/' + data.id + '/copy'), data),
    editContractsApprovalRounds: (data) => asJson(axios).put(getVerUri('settings/contract/approval-workflow/' + data.id), data),
    deleteContractsApprovalRounds: (id) => asJson(axios).delete(getVerUri('settings/contract/approval-workflow/' + id)),
    scopesApprovalRounds: (id) => asJson(axios).get(getVerUri('settings/scope/approval-workflow/' + id)),
    createScopesApprovalRounds: (data) => asJson(axios).post(getVerUri('settings/scope/approval-workflow'), data),
    editScopesApprovalRounds: (data) => asJson(axios).put(getVerUri('settings/scope/approval-workflow/' + data.id), data),
    deleteScopesApprovalRounds: (id) => asJson(axios).delete(getVerUri('settings/scope/approval-workflow/' + id)),
    copyScopesApproval: (data) => asJson(axios).post(getVerUri('settings/scope/approval-workflow/' + data.id + '/copy'), data),
    getAccountInfo: () => asJson(axios).get(getVerUri('account')),
    updateAccountInfoSender: (data) => asJson(axios).post(getVerUri('account/sender-email'), data),
    closeAccountInfo: () => asJson(axios).post(getVerUri('account/close')),
    updateAdminsAccountInfo: (data) => asJson(axios).post(getVerUri('account/update-admins'), data),
    getCompanyInfo: () => asJson(axios).get(getVerUri('settings/company/general-info')),
    updateCompanyInfo: (data) => asFormData(axios).post(getVerUri('settings/company/general-info/update'), data),
    verifiedCompanyTaxId: (id, data) => asJson(axios).post(getVerUri('settings/company/general-info/verified-tax-id/' + id), data),
    addCompanyCertification: (data) => asFormData(axios).post(getVerUri('settings/company/certification/add'), data),
    removeCompanyCertification: (data) => asJson(axios).post(getVerUri('settings/company/certification/remove'), data),
    updateCompanyCertification: (id, data) => asFormData(axios).post(getVerUri('settings/company/certification/update/' + id), data),
    addSubsidiaryCompany: (data) => asFormData(axios).post(getVerUri('settings/company/subsidiary-companies'), data),
    removeSubsidiaryCompany: (id) => asJson(axios).delete(getVerUri('settings/company/subsidiary-companies/' + id)),
    updateSubsidiaryCompany: (id, data) => asFormData(axios).post(getVerUri('settings/company/subsidiary-companies/' + id + '?_method=PUT'), data),
    addCompanyLicense: (data) => asFormData(axios).post(getVerUri('settings/company/license/add'), data),
    removeCompanyLicense: (data) => asJson(axios).post(getVerUri('settings/company/license/remove'), data),
    updateCompanyLicense: (id, data) => asFormData(axios).post(getVerUri('settings/company/license/update/' + id), data),
    fetchCompanyLicenses: (companyId) => asJson(axios).get(getVerUri('settings/company/license?company_id=' + companyId)),
    fetchCompanyOffices: (companyId) => asJson(axios).get(getVerUri('settings/company/office?company_id=' + companyId)),
    addCompanyOffice: (data) => asJson(axios).post(getVerUri('settings/company/office/add'), data),
    removeCompanyOffice: (data) => asJson(axios).post(getVerUri('settings/company/office/remove'), data),
    updateCompanyOffice: (id, data) => asJson(axios).post(getVerUri('settings/company/office/update/' + id), data),
    getCompanyTypes: () => asJson(axios).get(getVerUri('settings/company/company-type')),
    addCompanyType: (data) => asJson(axios).post(getVerUri('settings/company/company-type/create'), data),
    editCompanyType: (data) => asJson(axios).post(getVerUri('settings/company/company-type/edit'), data),
    deleteCompanyType: (data) => asJson(axios).post(getVerUri('settings/company/company-type/delete'), data),
    getDocumentNameDefault: () => asJson(axios).get(getVerUri('settings/company/document-name')),
    previewDocumentNameDefault: (data) => asJson(axios).get(getVerUri('settings/company/document-name/preview'), data),
    editDocumentNameDefault: (data) => asJson(axios).post(getVerUri('settings/company/document-name/edit'), data),
    getShortCodes: (data) => asJson(axios).get(getVerUri('settings/shortcode/list-paginated'), data),
    downloadShortCodes: () => asJson(axios).get(getVerUri('settings/shortcode/export'), {responseType: 'blob'}),
    editShortCode: (data) => asJson(axios).post(getVerUri('settings/shortcode/edit'), data),
    fetchPermissionGroup: (data = {}) => asJson(axios).get(getVerUri('settings/company/permission'), data),
    savePermissionGroup: (data) => asJson(axios).post(getVerUri('settings/company/permission'), data),
    fetchProfessions: () => asJson(axios).get(getVerUri('settings/professions')),
})
