import {createI18n} from 'vue-i18n';

const messages = {
    en: {
        menu: {
            dashboard: 'Dashboard',
        },
        validations: {
            alpha: 'This field must have only Latin letters.',
            digits: 'This field must have only numbers.',
            email: 'This field must be a valid email address.',
            number: 'This field must be a number.',
            numMax: 'Field must be no larger than {max}.',
            numMin: 'Field must be at least {min}.',
            password:
                'Password must have minimum 1 lower case character, 1 upper case character, 1 number and 1 special symbol. Password length must be 6-32 characters.',
            passwordConfirmation: 'The passwords are not matched.',
            required: 'This field is required.',
            strMax: 'Field must be no more than {max} characters.',
            strMin: 'Field must be at least {min} characters.',
            strMinPhone: 'Field must be at least {fact} characters.',
            strSize: 'Field must be {size} characters.',
            username: 'Field must have only Letters, numbers and . / _ - , chars.',
            exceptCharacters: 'This field can not contain the following characters: \'{characters}\'.',
            withoutSymbols: 'Field must have only Letters and numbers.',
            shortCodePlaceholder: 'Invalid merge field: {msg}.',
            extensions: 'Only files with the following extensions are allowed: {extensions}.',
            fileSize: 'Max size of the file should be {size}.',
            url: 'Please enter valid URL.',
            unique: 'The {val} has already been taken.',
            inArray: 'The {val} field does not exist in {other}.',
        },
        permission: {
            can_not:
                'You do not have the required permissions to complete this action. Please contact your company administrator to continue.',
            page_403: 'You have no permission to access this page. Please contact your administrator.',
        },
        toasts: {
            requestPermission: {
                title: 'Request permissions ',
                content:
                    'You do not have the required permissions to complete this action. Please contact your company administrator to continue.',
                btn: 'Contact admin',
            },
            updatePlan: {
                title: 'Update subscription plan',
                content:
                    'You do not have the required permissions to complete this action. Please upgrade your subscription plan to continue.',
                btn: 'Upgrade now',
            },
        },
        messages: {
            auth_failed: 'Authentication failed',
            given_data_invalid: 'The given data was invalid',
            something_went_wrong: 'Whoops, something went wrong',
            unauthorized: 'Unauthorized',
            unsaved: 'You have unsaved changes. <br /> Are you sure you want to cancel them?',
        },
        default: {
            empty: 'N/A',
        },
        plan: {
            generalContractor: 'General contractor',
            subContractor: 'Subcontractor',
        },
    },
};

const i18n = createI18n({
    locale: getBrowserLocale(),
    fallbackLocale: 'en',
    messages,
    silentTranslationWarn: true,
});

export default i18n;

function getBrowserLocale() {
    const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

    if (!navigatorLocale) {
        return undefined;
    }

    return navigatorLocale.trim().split(/-|_/)[0];
}
