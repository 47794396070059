<template>
  <CRow :key="key" :xs="{ cols: 1, gutter: 3 }" class="text-dark">
    <CCol>
      <CRow>
        <CFormLabel class="col-12 col-form-label filterLabelWidth">Company Name</CFormLabel>
        <CCol class="mx-0">
          <CFormInput v-model="form.company_name" placeholder="Company Name" />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <CRow>
        <CFormLabel class="col-12 filterLabelWidth col-form-label">Contact Name</CFormLabel>
        <CCol class="mx-0">
          <CFormInput v-model="form.contact_name" placeholder="Contact Name" />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import mixin from './mixin'
export default {
  name: 'Company',
  mixins: [mixin],
  data() {
    return {
      form: {
        company_name: '',
        contact_name: '',
      },
    }
  },
  mounted() {
    this.$nextTick(() => this.setOrigin())
  },
  methods: {
    getBadgeItems(outForm = undefined) {
      const form = outForm ? outForm : this.form
      return [
        { label: 'Company', value: form.company_name, key: ['company_name'] },
        { label: 'Contact', value: form.contact_name, key: ['contact_name'] },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .filterLabelWidth {
    width: 125px !important;
  }

  .countFilterBlock {
    .filterLabelTask {
      width: 70px !important;
    }
    .filterLabelOverdue {
      width: 125px !important;
    }

    input {
      padding: 0 !important;
      width: 40px;
      text-align: center;
    }

    .rInput {
      padding-left: 5px
    }
    .lInput {
      padding-right: 5px
    }
  }
}
</style>
