import Gate from '@/services/Gate/Gate'
import router from '../index'

export const canCreate = async ({to}) => {
    await Gate.can('taskCreate', 'project', to.params.id).then(async (res) => {
        if (!res) await router.replace({name: 'PermissionsError'})
        return res
    })
}

export const canView = async ({to}) => {
    await Gate.can('view', 'task', to.params.task_id).then(async (res) => {
        if (!res) await router.replace({name: 'PermissionsError'})
        return res
    })
}
