import {asJson, getVerUri} from '@/services/ApiService'

export default (axios) => ({
    getChatsData: (data) => asJson(axios).get(getVerUri('message/get-chat-data'), {params: data}),
    getMessages: (data) => asJson(axios).get(getVerUri('message/show'), {params: data}),
    sendMessage: (data) => asJson(axios).post(getVerUri(`message/create`), data),
    deleteMessage: (data) => asJson(axios).post(getVerUri(`message/delete`), data),
    editMessage: (data) => asJson(axios).post(getVerUri(`message/edit`), data),
    setAsMarked: (data) => asJson(axios).post(getVerUri(`message/set-as-marked`), data),
});
