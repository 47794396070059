import ApiService from '@/services/ApiService'
import Gate from '@/services/Gate/Gate'

export default {
    namespaced: true,
    state: {
        templates: [],
        editableTemplate: null,
        editableTemplateEditor: null,
        tableKey: 0,
        loadingTemplates: false,
        paginationMeta: {per_page: 10},
        indexQueryOptions: {},
        editorViewScalePercents: 100,
        entity: {},
        permissions: {
            view: false,
            list: false,
            create: false,
            edit: false,
            delete: false,
        },
    },
    getters: {
        indexParams(state) {
            return {
                ...state.indexQueryOptions,
                ...{
                    page: state.paginationMeta.current_page,
                    per_page: state.paginationMeta.per_page,
                },
            }
        },
        editableTemplate(state) {
            return state.editableTemplate ? state.editableTemplate : {}
        },
        currentEditableTemplateHasSignaturesForBothSide(state) {
            const signatures =
                state.editableTemplateEditor?.commands.getNodesByType('signature')

            if (!signatures) return false

            return (
                signatures.length >= 2 &&
                signatures
                    .map((s) => s.actor)
                    .filter((value, index, self) => {
                        return self.indexOf(value) === index
                    }).length === 2
            )
        },
    },
    mutations: {
        SET_STATE(state, {key, value}) {
            if (typeof value === 'object') {
                state[key] = Object.assign(state[key], value)
            } else {
                state[key] = value
            }
        },
        SET_EDITABLE_TEMPLATE_ATTRIBUTE(state, {key, value}) {
            state.editableTemplate[key] = value
        },
        SET_EDITABLE_TEMPLATE(state, value) {
            state.editableTemplate = value
        },
        SET_TEMPLATES: (state, value) => {
            state.templates = value
        },
        SET_LOADING_STATUS(state, value) {
            state.loadingTemplates = !!value
        },
        SET_TEMPLATES_PAGINATION_META(state, value) {
            state.paginationMeta = value
        },
        INCREASE_RENDER_KEY(state) {
            state.tableKey++
        },
        setTemplateEntity: (state, data) => (state.entity = data),
        setTemplatePermissions: async (state) => {
            state.permissions = {
                view: await Gate.can('view', 'template', state.entity.id),
                list: await Gate.can('list', 'template'),
                edit: await Gate.can('edit', 'template'),
                delete: await Gate.can('delete', 'template'),
                create: await Gate.can('create', 'template'),
            }
        },
    },
    actions: {
        fetchTemplatePermissions({commit}, id) {
            commit('setTemplateEntity', {})
            return new Promise((resolve, reject) => {
                ApiService.api.app
                    .getModelPermissions('template', id)
                    .then((res) => {
                        commit('setTemplateEntity', res.data.data)
                        commit('setTemplatePermissions')
                        resolve(res.data.data)
                    })
                    .catch((err) => {
                        commit('setProjectEntity', {})
                        commit('setTemplatePermissions')
                        resolve({})
                        reject(err)
                    })
            })
        },
        async getTemplates({commit, getters}, params) {
            commit('SET_LOADING_STATUS', true)
            const res = await ApiService.api.template.getTemplates({
                ...getters.indexParams,
                ...params,
            })
            commit('SET_TEMPLATES', res.data.data)
            commit('SET_TEMPLATES_PAGINATION_META', res.data.meta)
            commit('SET_LOADING_STATUS', false)
            return res
        },
        async getTemplate({commit = commit}, {template_id}) {
            return await ApiService.api.template.getTemplate({template_id})
        },
        async storeTemplate({commit = commit}, payload) {
            return await ApiService.api.template.storeTemplate(payload)
        },
        async archiveTemplate({commit = commit}, templateId) {
            return await ApiService.api.template
                .archiveTemplate(templateId)
                .catch((error) => error)
        },
    },
}
